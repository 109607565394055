<template>
  <v-dialog v-model="dialog" persistent max-width="70%">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar class="toolbar-color">
          <span class="headline ml-2">
            {{ labelBtn != "Salvar" ? "Editar Função" : "Cadastrar Função" }}
          </span>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form">
            <v-row>
              <v-col cols="12" sm="6" md="12" lg="12">
                <v-text-field
                  label="Descricao"
                  v-model="form.descricao"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3">
                <v-text-field
                  label="Código Função"
                  v-model="form.cod_funcao"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3">
                <BaseSelect
                  prepend-inner-icon="mdi-file-table-box-multiple-outline"
                  clearable
                  single-line
                  label="Bandeira"
                  item-text="bandeira"
                  item-value="id_band"
                  service="sistemaService.tipoBandeiras"
                  v-model="form.id_band"
                  :isCompany="true"
                />
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3">
                <BaseSelect
                  prepend-inner-icon="mdi-account-tie"
                  clearable
                  item-text="cargo"
                  item-value="id_cargo"
                  label="Cargo"
                  v-model="form.id_cargo"
                  service="comissaoService.cargos"
                />
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3">
                <v-autocomplete
                  prepend-inner-icon="mdi-hand-coin-outline"
                  label="Tem Gratificação"
                  :items="[
                    { text: 'Sim', value: 'S' },
                    { text: 'Não', value: 'N' }
                  ]"
                  v-model="form.tem_gratificacao"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3">
                <v-text-field
                  prepend-inner-icon="mdi-hand-coin-outline"
                  label="Preço Gratificação"
                  v-model="form.pec_gratificacao"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3">
                <BaseSelect
                  prepend-inner-icon="mdi-flag-checkered"
                  label="Tipo Meta"
                  clearable
                  service="campanhaService.tipoMeta"
                  item-text="tipo_meta"
                  item-value="id_tipo_meta"
                  v-model="form.id_tipo_meta"
                />
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3">
                <BaseSelect
                  label="Papel Campanha"
                  clearable
                  service="sistemaService.papeis"
                  item-text="nome"
                  item-value="id"
                  v-model="form.id_papel_campanha"
                />
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3">
                <BaseSelect
                  prepend-inner-icon="mdi-account-tie"
                  label="Função Nível"
                  item-text="funcao_nivel"
                  item-value="id_funcao_nivel"
                  v-model="form.id_funcao_nivel"
                  service="sistemaService.funcaoNivel"
                />
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3">
                <v-text-field
                  label="Papel flyVendas"
                  v-model="form.papel_flyvendas"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3">
                <v-autocomplete
                  label="Integra flyVendas"
                  :items="[
                    { text: 'Sim', value: 'S' },
                    { text: 'Não', value: 'N' }
                  ]"
                  :rules="[rules.required]"
                  v-model="form.integra_flyvendas"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3">
                <v-autocomplete
                  label="Loca Automatico"
                  :items="[
                    { text: 'Sim', value: 'S' },
                    { text: 'Não', value: 'N' }
                  ]"
                  :rules="[rules.required]"
                  v-model="form.loca_automatico"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3">
                <v-autocomplete
                  prepend-inner-icon="mdi-clock-time-four-outline"
                  label="Calcula Horas Vendas"
                  :items="[
                    { text: 'Sim', value: 'S' },
                    { text: 'Não', value: 'N' }
                  ]"
                  :rules="[rules.required]"
                  v-model="form.calcula_horas_vend"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3">
                <v-autocomplete
                  prepend-inner-icon="mdi-clock-time-four-outline"
                  label="Calcula Horas Funilaria"
                  :items="[
                    { text: 'Sim', value: 'S' },
                    { text: 'Não', value: 'N' }
                  ]"
                  :rules="[rules.required]"
                  v-model="form.calcula_horas_funilaria"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3">
                <v-autocomplete
                  label="Soma Cotas"
                  :items="[
                    { text: 'Sim', value: 'S' },
                    { text: 'Não', value: 'N' }
                  ]"
                  :rules="[rules.required]"
                  v-model="form.soma_cotas"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3">
                <v-autocomplete
                  label="Soma Novos"
                  :items="[
                    { text: 'Sim', value: 'S' },
                    { text: 'Não', value: 'N' }
                  ]"
                  :rules="[rules.required]"
                  v-model="form.soma_novo"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3">
                <v-autocomplete
                  label="Soma Usados"
                  :items="[
                    { text: 'Sim', value: 'S' },
                    { text: 'Não', value: 'N' }
                  ]"
                  :rules="[rules.required]"
                  v-model="form.soma_usado"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3">
                <v-autocomplete
                  prepend-inner-icon="mdi-account-group"
                  label="Soma Equipe"
                  :items="[
                    { text: 'Sim', value: 'S' },
                    { text: 'Não', value: 'N' }
                  ]"
                  :rules="[rules.required]"
                  v-model="form.soma_equipe"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3">
                <v-autocomplete
                  prepend-inner-icon="mdi-truck-delivery-outline"
                  label="Soma Entrega"
                  :items="[
                    { text: 'Sim', value: 'S' },
                    { text: 'Não', value: 'N' }
                  ]"
                  :rules="[rules.required]"
                  v-model="form.soma_entrega"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3">
                <v-autocomplete
                  label="Ativa Campanha"
                  :items="[
                    { text: 'Sim', value: 'S' },
                    { text: 'Não', value: 'N' }
                  ]"
                  :rules="[rules.required]"
                  v-model="form.ativa_campanha"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3">
                <v-autocomplete
                  label="Ativar Função"
                  :items="[
                    { text: 'Sim', value: 'S' },
                    { text: 'Não', value: 'N' }
                  ]"
                  :rules="[rules.required]"
                  v-model="form.ativo"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close">Fechar</v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="labelBtn != 'Salvar' ? editarFuncao() : cadastrarFuncao()"
          >{{ labelBtn }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseSelect from "@/components/shared/BaseSelectService";

import rules from "@/mixins/inputRules";
import sistema from "@/services/http/sistemaService";

export default {
  name: "ModalCadastroColaboradores",

  mixins: [rules],

  components: {
    BaseSelect
  },

  props: {
    dialog: {
      type: Boolean,
      default: false
    },

    labelBtn: {
      type: String
    },

    createFuncao: {
      type: Object
    }
  },

  data() {
    return {
      form: {}
    };
  },

  watch: {
    dialog(value) {
      return value;
    },

    labelBtn(value) {
      return value;
    },

    createFuncao(value) {
      this.form = value;
    }
  },

  methods: {
    async cadastrarFuncao() {
      const validateStatus = this.$refs.form.validate();

      if (validateStatus) {
        await sistema()
          .funcoes()
          .store(this.form, {
            notification: true,
            message: "Função cadastrada com sucesso!"
          });
      }
    },

    async editarFuncao() {
      const validateStatus = this.$refs.form.validate();
      if (validateStatus) {
        await sistema()
          .funcoes(this.form.id_funcao)
          .update(this.form, {
            notification: true,
            message: "Função editada com sucesso!"
          });
      }
    },

    close() {
      this.$emit("close");
      this.$refs.form.reset();
    }
  }
};
</script>

<style></style>
