<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="infoFuncoes"
      :loading="loading"
      :paginate="true"
      :search="search"
      sort-by="id_band"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Lista de Funções</v-toolbar-title>

          <v-spacer></v-spacer>
          <RegisterBottom
            v-can-access="121"
            @click="(dialog = true), (labelBtn = 'Salvar')"
          />
          <FilterFuncao @selectedFilters="fetchFuncoes((filters = $event))" />
        </v-toolbar>
      </template>
      <template v-slot:[`item.id_band`]="{ item }">
        <BaseSegmento :segmento="item.id_band" />
      </template>
      <template v-slot:[`item.cod_funcao`]="{ item }">
        {{ item.cod_funcao }}
      </template>
      <template v-slot:[`item.descricao`]="{ item }">
        {{ item.descricao | TitleCase }}
      </template>
      <template v-slot:[`item.id_tipo_meta`]="{ item }">
        {{ item.id_tipo_meta }}
      </template>
      <template v-slot:[`item.ativa_campanha`]="{ item }">
        <v-icon
          :color="statusItem(item.ativa_campanha).color"
          @click="updateStatus(item, 'ativa_campanha', 'ativa campanha')"
        >
          {{ statusItem(item.ativa_campanha).icon }}
        </v-icon>
      </template>
      <template v-slot:[`item.integra_flyvendas`]="{ item }">
        <v-icon
          :color="statusItem(item.integra_flyvendas).color"
          @click="updateStatus(item, 'integra_flyvendas', 'integra flyvendas')"
        >
          {{ statusItem(item.integra_flyvendas).icon }}
        </v-icon>
      </template>
      <template v-slot:[`item.ativo`]="{ item }">
        <v-chip
          :color="statusFuncao(item.ativo) == 'Ativo' ? 'green' : 'red'"
          @click="updateStatus(item, 'ativo', 'status')"
          dark
        >
          {{ statusFuncao(item.ativo) }}
        </v-chip>
      </template>
      <template v-slot:[`item.acoes`]="{ item }">
        <!-- <v-btn
          v-can-access="122"
          icon
          @click="editItem(item)"
          color="orange"
          dark
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>-->
        <!-- <v-btn
          @click="deleteItem(item)"
          class="ml-2 white--text"
          color="red"
          small
        >
          <v-icon >mdi-trash-can</v-icon>
        </v-btn> -->

        <IconBottom :name="'edit'" v-can-access="122" @click="editItem(item)" />
      </template>
    </BaseTable>
    <ModalCadastroFuncoes
      :dialog="dialog"
      :labelBtn="labelBtn"
      :createFuncao="create"
      @close="refresh()"
    />
  </div>
</template>

<script>
import serviceEmpresa from "@/services/empresa";
import IconBottom from "@/components/shared/bottons/IconBottom";
import sistema from "@/services/http/sistemaService";
import BaseTable from "@/components/shared/NewBaseTable.vue";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import FilterFuncao from "./FilterFuncao.vue";
import BaseSegmento from "@/components/shared/BaseSegmento.vue";
import ModalCadastroFuncoes from "./ModalCadastroFuncoes.vue";

export default {
  name: "TabelaFuncoes",

  components: {
    BaseTable,
    RegisterBottom,
    FilterFuncao,
    BaseSegmento,
    ModalCadastroFuncoes,
    IconBottom
  },

  data() {
    return {
      labelBtn: "Salvar",
      dialog: false,
      create: {},
      loading: true,
      search: "",
      filters: {},
      headers: [
        { text: "Bandeira", value: "id_band" },
        { text: "Cod.", value: "cod_funcao" },
        { text: "Função", value: "descricao" },

        { text: "Meta", value: "tipo_meta", align: "center" },
        { text: "Ativa Campanha", value: "ativa_campanha", align: "center" },
        {
          text: "Integra flyVendas",
          value: "integra_flyvendas",
          align: "center"
        },
        { text: "Papel flyvendas", value: "papel_flyvendas", align: "center" },
        { text: "Status", value: "ativo", align: "center" },
        { text: "Ações", value: "acoes", align: "center" }
      ],

      infoFuncoes: [],
      status: {
        S: {
          icon: "mdi-check",
          color: "success"
        },
        N: {
          icon: "mdi-close",
          color: "error"
        }
      }
    };
  },

  methods: {
    statusItem(item) {
      return this.status[item];
    },

    statusFuncao(item) {
      if (item == "S") item = "Ativo";
      if (item == "N") item = "Inativo";

      return item;
    },

    async fetchFuncoes(filter) {
      this.loading = true;
      let { data } = await sistema()
        .funcoes()
        .show({
          ativo: "S",
          per_page: -1,
          ...filter
        });
      this.loading = false;
      this.infoFuncoes = data.data;
    },
    refresh() {
      this.dialog = false;
      this.fetchFuncoes(this.filters);
    },

    async updateStatus(item, chave, descricao) {
      await this.$swal.confirm(
        `Editar ${descricao} da função`,
        `Deseja ${item[`${chave}`] == "S" ? "Desativar" : "Ativar"} a Função ${
          item.descricao
        }?`
      );
      try {
        this.loading = true;
        item[`${chave}`] = item[`${chave}`] == "S" ? "N" : "S";

        const funcao = `{
          "cod_funcao": "${item.cod_funcao}",
          "id_band": "${item.id_band}",
          "${chave}": "${item[chave]}"
        }`;
        let object = JSON.parse(funcao);
        await serviceEmpresa.putFuncoes(item.id_funcao, object);
      } catch (error) {
        console.error(error.response);
        this.$notify({
          type: "error",
          text: `Erro ao editar ${descricao} função`
        });
      } finally {
        this.loading = false;
        this.fetchFuncoes(this.filters);
      }
    },

    editItem(item) {
      this.create = Object.assign({}, item);
      this.labelBtn = "Editar";
      this.dialog = true;
    }
  },

  mounted() {
    this.fetchFuncoes();
  }
};
</script>
