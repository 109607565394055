var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseTable',{staticClass:"table-shadow",attrs:{"headers":_vm.headers,"items":_vm.infoFuncoes,"loading":_vm.loading,"paginate":true,"search":_vm.search,"sort-by":"id_band"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Lista de Funções")]),_c('v-spacer'),_c('RegisterBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(121),expression:"121"}],on:{"click":function($event){(_vm.dialog = true), (_vm.labelBtn = 'Salvar')}}}),_c('FilterFuncao',{on:{"selectedFilters":function($event){_vm.fetchFuncoes((_vm.filters = $event))}}})],1)]},proxy:true},{key:"item.id_band",fn:function(ref){
var item = ref.item;
return [_c('BaseSegmento',{attrs:{"segmento":item.id_band}})]}},{key:"item.cod_funcao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cod_funcao)+" ")]}},{key:"item.descricao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("TitleCase")(item.descricao))+" ")]}},{key:"item.id_tipo_meta",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id_tipo_meta)+" ")]}},{key:"item.ativa_campanha",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.statusItem(item.ativa_campanha).color},on:{"click":function($event){return _vm.updateStatus(item, 'ativa_campanha', 'ativa campanha')}}},[_vm._v(" "+_vm._s(_vm.statusItem(item.ativa_campanha).icon)+" ")])]}},{key:"item.integra_flyvendas",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.statusItem(item.integra_flyvendas).color},on:{"click":function($event){return _vm.updateStatus(item, 'integra_flyvendas', 'integra flyvendas')}}},[_vm._v(" "+_vm._s(_vm.statusItem(item.integra_flyvendas).icon)+" ")])]}},{key:"item.ativo",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.statusFuncao(item.ativo) == 'Ativo' ? 'green' : 'red',"dark":""},on:{"click":function($event){return _vm.updateStatus(item, 'ativo', 'status')}}},[_vm._v(" "+_vm._s(_vm.statusFuncao(item.ativo))+" ")])]}},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('IconBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(122),expression:"122"}],attrs:{"name":'edit'},on:{"click":function($event){return _vm.editItem(item)}}})]}}],null,true)}),_c('ModalCadastroFuncoes',{attrs:{"dialog":_vm.dialog,"labelBtn":_vm.labelBtn,"createFuncao":_vm.create},on:{"close":function($event){return _vm.refresh()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }